export const radioClasses = `
  form-check-input
  box-border
  appearance-none
  h-4
  w-4
  border-solid
  border 
  border-black-primary
  rounded-full
  bg-white
  cursor-pointer
  checked:bg-radio-checked
  formkit-invalid:border-error
  disabled:border-gray-50
  disabled:cursor-default
  disabled:checked:bg-radio-checked-disabled
  peer
`;

export const radioLabelClasses = `
  formkit-invalid:text-error 
  peer-disabled:text-gray-50
  ml-2
  cursor-pointer
  peer-disabled:cursor-default
`;

export const radioWrapperClasses = `
  inline-flex 
  items-center
`;
