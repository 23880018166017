import { FormKitNode, FormKitTypeDefinition } from "@formkit/core";
import {
	outer,
	inner,
	help,
	boxHelp,
	messages,
	message,
	prefix,
	suffix,
	fieldset,
	decorator,
	box,
	legend,
	boxOption,
	boxOptions,
	boxWrapper,
	boxLabel,
	options,
	radios,
	checkboxes,
	$if,
	$extend,
	// @ts-ignore
} from "@formkit/inputs";
import { chipBasicClasses, chipAfterIconClasses, chipBeforeIconClasses, chipContainerClasses } from "../styles/chip.js";

export const ocmChipRadio: FormKitTypeDefinition = getChip("radio", radios);
export const ocmChipCheckbox: FormKitTypeDefinition = getChip("checkbox", checkboxes);

function getChip(tagName: string, typeInstance: FormKitNode): FormKitTypeDefinition {
	return {
		schema: outer(
			$if(
				"$options == undefined",
				/**
				 * Single radio structure.
				 */
				boxWrapper(inner(prefix(), box(), decorator(), suffix()), $if("$label", boxLabel("$label"))),
				/**
				 * Multi radio structure.
				 */
				fieldset(
					legend("$label"),
					help("$help"),
					$extend(
						boxOptions(
							boxOption(
								boxWrapper(
									inner(
										prefix(),
										$extend(box(), {
											bind: "$option.attrs",
											attrs: {
												id: "$option.attrs.id",
												value: "$option.value",
												checked: "$fns.isChecked($option.value)",
												type: tagName,
												class: "peer hidden",
											},
										}),
										$extend(boxLabel(), {
											attrs: {
												class: chipBasicClasses,
											},
											children: [
												{
													$el: "svg",
													if: "$option.iconFirst",
													attrs: {
														class: "svg-icon" + chipBeforeIconClasses,
													},
													children: [
														{
															$el: "use",
															attrs: {
																href: "$option.iconFirst",
																"xlink:href": "$option.iconFirst",
															},
														},
													],
												},
												{
													$el: "span",
													attrs: {
														// class: "$classes.message",
													},
													children: "$option.label",
												},
												{
													$el: "svg",
													if: "$option.iconLast",
													attrs: {
														class: "svg-icon" + chipAfterIconClasses,
													},
													children: [
														{
															$el: "use",
															attrs: {
																href: "$option.iconLast",
																"xlink:href": "$option.iconLast",
															},
														},
													],
												},
											],
										}),
										decorator(),
										suffix()
									)
								),
								boxHelp("$option.help")
							)
						),
						{
							attrs: { class: chipContainerClasses },
						}
					)
				)
			),
			// Help text only goes under the input when it is a single.
			$if("$options == undefined && $help", help("$help")),
			messages(message("$message.value"))
		),
		/**
		 * The type of node, can be a list, group, or input.
		 */
		type: "input",
		/**
		 * An array of extra props to accept for this input.
		 */
		props: ["options", "onValue", "offValue", "chipType"],
		/**
		 * Forces node.props.type to be this explicit value.
		 */
		// forceTypeProp: 'radio',
		/**
		 * Additional features that should be added to your input
		 */
		features: [options, typeInstance],
	};
}
