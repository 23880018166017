"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearSearch = exports.searchInput = void 0;
var search_query_1 = require("@/queries/search.query");
var vue_1 = require("vue");
exports.searchInput = (0, vue_1.ref)("");
var clearSearch = function () {
    exports.searchInput.value = "";
    search_query_1.error.value = "";
    search_query_1.resultResponse.value = undefined;
};
exports.clearSearch = clearSearch;
