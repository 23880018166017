import { Ref, computed, ref, watch, watchEffect } from "vue";
import { currentFormFlow } from "./use-polly";
import { RouteRecordName } from "vue-router";
import router from "@/router";
import { isContactFormDirty } from "./use-messageData";

export const completedSteps = ref<Set<RouteRecordName>>(new Set());

export const areAllStepsCompleted = computed(() => completedSteps.value.size === currentFormFlow.value.length);

export function useRerouteToValidStep(formKitRef: Ref): void {
	const currentStep = router.currentRoute.value.name;
	const currentStepIndex = computed(() => currentFormFlow.value.findIndex((stepName) => stepName === currentStep));

	watchEffect(() => {
		updateCompletedSteps();
	});

	addEventListener("beforeunload", (event) => {
		if (isContactFormDirty()) event.preventDefault();
	});

	rerouteToFirstIncompleteStep();
	watch(currentFormFlow, rerouteToFirstIncompleteStep);

	function updateCompletedSteps(): void {
		const formValidityState = formKitRef.value?.node?.context?.state?.valid;
		if (currentStep === null || currentStep === undefined) return;
		if (formValidityState) {
			completedSteps.value.add(currentStep);
		} else {
			completedSteps.value.delete(currentStep);
		}
	}

	function rerouteToFirstIncompleteStep(): void {
		const firstIncompleteStep = getFirstIncompleteStepIndex();
		if (firstIncompleteStep > -1 && currentStepIndex.value > firstIncompleteStep) {
			const routeName = currentFormFlow.value[firstIncompleteStep];
			if (routeName) router.replace({ name: routeName });
		}
	}

	function getFirstIncompleteStepIndex(): number {
		return currentFormFlow.value.findIndex((stepName) => !completedSteps.value.has(stepName));
	}
}
