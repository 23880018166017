/* Button Styling */
export const dimensionsButton = `
	h-11.5
	px-8
	py-3
`;

const focusOutlineButton = `
	relative
	focus:before:content-['']
	focus:before:bg-transparent 
	focus:before:border-2
	focus:before:border-solid
	focus:before:absolute
	focus:before:w-[calc(100%+12px)]
	focus:before:h-[calc(100%+12px)]
	focus:before:rounded-full
`

export const buttonDefaults = `
	rounded-full
	inline-flex
	items-center
	justify-center
	box-border
	font-semibold
`;

export const buttonPrimary = `
	bg-black-primary
	text-white
	
	hover:bg-black-hover
	hover:border-black-hover

	disabled:bg-gray-50
	disabled:text-gray-60
	disabled:pointer-events-none
	disabled:select-none
	disabled:border-none

	border-2
	border-solid
	border-black-primary

	focus:before:border-black-primary 
` + focusOutlineButton ;

export const buttonPrimaryDark = `
	bg-white
	text-black-primary

	border
	border-solid
	border-white

	hover:bg-white-hover

	disabled:bg-gray-50
	disabled:border-gray-50
	disabled:text-gray-40
	disabled:pointer-events-none
	disabled:select-none

	focus:before:border-white 
` + focusOutlineButton;

export const buttonProminent = `
	text-white
	bg-red-primary
	hover:bg-red-hover
	hover:border-red-hover

	disabled:bg-gray-50
	disabled:text-gray-60
	disabled:pointer-events-none
	disabled:select-none
	disabled:border-none

	border-2
	border-solid
	border-red-primary

	focus:before:border-red-primary
` + focusOutlineButton ;

export const buttonSecondary = `
	bg-white
	text-black-primary
	border
	border-solid
	border-black-primary
	hover:shadow-inset
	disabled:text-gray-40
	disabled:pointer-events-none
	disabled:select-none
	disabled:border-gray-50

	relative
	focus:before:content-['']
	focus:before:bg-transparent 
	focus:before:border-2
	focus:before:border-solid
	focus:before:absolute
	focus:before:w-[calc(100%+10px)]
	focus:before:h-[calc(100%+10px)]
	focus:before:rounded-full
	focus:before:border-black-primary 
`;

export const buttonSecondaryDark = `
	bg-transparent
	text-white
	border
	border-solid
	border-white

	hover:shadow-inset

	disabled:text-gray-60
	disabled:pointer-events-none
	disabled:select-none
	disabled:border-gray-50

	focus:before:border-white
` + focusOutlineButton;

export const buttonText = `
	bg-transparent
	border-2
	border-transparent
	text-black-primary

	hover:text-black

	disabled:text-gray-40
	disabled:pointer-events-none
	disabled:select-none

	focus:border-2
	focus:border-solid
	focus:border-black-primary
`;

export const buttonIcon = `
	h-11.5
	w-11.5
`;

export const buttonIconSmall = `
	w-8
	h-8
`;
/* END - Button Styling */
