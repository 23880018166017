import { ref } from "vue";
import { $axios } from "@ocm/axios";

export const customerData = ref(null);
export const getCustomerData = async () => {
	try {
		const { data } = await $axios.get(`rest/contactcenter/customerData`);
		customerData.value = data;
	} catch (err) {
		console.error(err);
	}
};
