import { computed } from "vue";
import { currentFormFlow } from "./use-polly";
import { getAuthLevel, hasFullAccess } from "@/services/checkAuth";
import { currentAuthLevel } from "@/services/checkAuth";

export const authRequired = computed((): boolean => currentFormFlow.value.some((page) => page === "imageUploadPage"));

export async function hasUserPermission(): Promise<boolean> {
	if (authRequired.value === true) {
		if (currentAuthLevel.value === undefined) await getAuthLevel();
		if (!hasFullAccess.value) {
			return false;
		}
	}
	return true;
}
