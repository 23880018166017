// ---- Chip Container Classes ----

export const chipContainerClasses = `
ml-0 
mb-0 
flex 
flex-wrap 
list-none 
formkit-options 
`;



// ---- Chip Input Classes (FormKit & without FormKit) ----

const chipBasicClassesButton = `
	hover:bg-gray-40
	focus:bg-gray-40
	disabled:border-gray-50
	disabled:text-gray-40
	disabled:border
`;

const chipBasicClassesRadioCheckbox = `
	peer-hover:bg-gray-40
	peer-hover:cursor-pointer
	peer-hover:peer-disabled:cursor-auto
	peer-focus:bg-gray-40
	peer-checked:border-2
	peer-checked:border-black-primary
	peer-checked:py-[7px]
	peer-checked:px-[15px]
	peer-checked:hover:bg-white
	peer-checked:peer-hover:bg-white
	peer-checked:peer-focus:bg-white
	peer-disabled:border-gray-50
	peer-disabled:text-gray-40
	peer-disabled:border
	peer-disabled:py-2
	peer-disabled:px-4
	peer-disabled:hover:bg-white
	peer-disabled:peer-hover:bg-white
`; // smaller padding checked and invalid is set so that the elements around do not move because of the border size change

const chipInvalidClassesFormKit = `
	formkit-invalid:border-error
	formkit-invalid:border-2
	formkit-invalid:py-[7px]
	formkit-invalid:px-[15px]
	formkit-invalid:peer-hover:bg-white
	formkit-invalid:peer-checked:border-error
	formkit-invalid:peer-focus:bg-gray-10
`

export const chipBasicClasses = `
	mr-2
	mb-2
	py-2
	px-4
	flex
	border 
	bg-white
	rounded-full
	border-solid
	border-gray-60
` + chipBasicClassesButton + chipBasicClassesRadioCheckbox + chipInvalidClassesFormKit;



// ---- Invalid States for non-FormKit ----

const chipInvalidClassesButton = `
hover:bg-white
focus:bg-gray-10
`; // bg-gray-10 instead of bg-white, since it has a higher specificity than bg-white 

const chipInvalidClassesRadioCheckbox = `
peer-hover:bg-white
peer-checked:border-error
peer-focus:bg-gray-10
`;

export const chipInvalidClasses = `
	border-error
	border-2
	py-[7px]
	px-[15px]
` + chipInvalidClassesButton + chipInvalidClassesRadioCheckbox;




// ---- Icon Classes ----

const chipIconClasses = `
	w-5 
	h-5
	align-middle 
`;

export const chipBeforeIconClasses = `
	mr-2
	order-first
` + chipIconClasses;

export const chipAfterIconClasses = `
	ml-2
` + chipIconClasses;