import { currentCollection } from "@/composables/use-polly";
import { pushGAevent } from "@/services/pushGAevent";
import { computed } from "vue";

const currentTopic = computed(() => currentCollection.value?.name || "");

// Please keep the confluence documentation up-to-date: https://redbox.outletcity.com/confluence/display/TUB/FR+-+Custom-Tracking
export const trackShowMoreFAQsClicked = (): void => {
	pushGAevent("faq_show_more", currentTopic.value);
};

export const trackQuestionClicked = (questionName: string): void => {
	pushGAevent("faq_clicked", `${currentTopic.value}_${questionName}`);
};

export const trackContinueToFormClicked = (): void => {
	pushGAevent("proceed_to_contactform", currentTopic.value);
};

export const trackNotHelpfulBtn = (articleName: string): void => {
	pushGAevent("faq_not_helpful_preselect", `${currentTopic.value}_${articleName}`);
};

export const trackFeedbackSubmit = (articleName: string, positiveFeedback: boolean): void => {
	const eventAction = positiveFeedback ? "faq_helpful_submit" : "faq_not_helpful_submit";
	pushGAevent(eventAction, `${currentTopic.value}_${articleName}`);
};

export const trackSearchTerm = (searchTerm: string, searchResult: string): void => {
	pushGAevent("faq_search_submit", `${searchTerm}: ${searchResult}`);
};

export const trackSearchResultClicked = (searchTerm: string, answerText: string): void => {
	pushGAevent("faq_search_result_clicked", `${searchTerm}_${answerText}`);
};
