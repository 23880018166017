"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClientSideAuthLevel = exports.isRoleAnonymous = exports.isRoleCoop = exports.isRoleEasyAuth = exports.isRoleRememberMe = exports.isRoleFullAccess = void 0;
var AuthLevelType;
(function (AuthLevelType) {
    AuthLevelType[AuthLevelType["FULL_ACCESS"] = 900] = "FULL_ACCESS";
    AuthLevelType[AuthLevelType["REMEMBER_ME"] = 500] = "REMEMBER_ME";
    AuthLevelType[AuthLevelType["EASY_AUTH"] = 300] = "EASY_AUTH";
    AuthLevelType[AuthLevelType["COOP"] = 200] = "COOP";
    AuthLevelType[AuthLevelType["ANONYMOUS"] = 0] = "ANONYMOUS";
})(AuthLevelType || (AuthLevelType = {}));
var isRoleFullAccess = function (authLevel) { return authLevel === AuthLevelType.FULL_ACCESS; };
exports.isRoleFullAccess = isRoleFullAccess;
var isRoleRememberMe = function (authLevel) { return authLevel === AuthLevelType.REMEMBER_ME; };
exports.isRoleRememberMe = isRoleRememberMe;
var isRoleEasyAuth = function (authLevel) { return authLevel === AuthLevelType.EASY_AUTH; };
exports.isRoleEasyAuth = isRoleEasyAuth;
var isRoleCoop = function (authLevel) { return authLevel === AuthLevelType.COOP; };
exports.isRoleCoop = isRoleCoop;
var isRoleAnonymous = function (authLevel) { return authLevel === AuthLevelType.ANONYMOUS; };
exports.isRoleAnonymous = isRoleAnonymous;
var getClientSideAuthLevel = function () { var _a, _b, _c; return (_c = (_b = (_a = window.recommendationData) === null || _a === void 0 ? void 0 : _a.Recommendation) === null || _b === void 0 ? void 0 : _b.customerData) === null || _c === void 0 ? void 0 : _c.authlevel; };
exports.getClientSideAuthLevel = getClientSideAuthLevel;
