import dataLayerPush from "@ocm/services/dataLayer.js";

export const pushGAevent = (action: string, label: string): void => {
	dataLayerPush({
		event: "GAevent",
		event_category: "contactcenter",
		event_action: action,
		event_label: label,
	});
};
