<template>
	<div class="skeleton max-w-full" />
</template>

<style scoped>
.skeleton {
	width: 100%; /* parent component may overwrite this */
	background-color: var(--color-gray-20);
	background-image: linear-gradient(90deg, transparent 25%, rgba(0, 0, 0, 0.02) 50%, transparent 75%);
	background-size: 200%;
	background-position-x: 150%;
	animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
	0% {
		background-position-x: 150%;
	}
	100% {
		background-position-x: -50%;
	}
}
</style>
