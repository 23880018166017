import { ref, Ref } from "vue";
import { $axios } from "@ocm/axios";
import { startContactForm } from "@/composables/use-pageNavigation";
import { FaqResult, FaqsByCollection } from "@/types/faqResults.type";

export const fetching = ref(false);
export const error = ref();
export const data: Ref<FaqsByCollection | undefined> = ref();

export const getFaqs = async (collectionId: string): Promise<void> => {
	try {
		fetching.value = true;
		const result: FaqResult = await $axios.get(`rest/contactcenter/faq/${collectionId}`);
		const obj = { ...data.value, [collectionId]: result.data.data };
		data.value = obj;
	} catch (err) {
		error.value = err;
		console.log(err);
		startContactForm(collectionId);
	} finally {
		fetching.value = false;
	}
};
