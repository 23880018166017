import { buttonDefaults, buttonPrimary, dimensionsButton, buttonSecondary } from "./button";
import { fileClassification } from "./formKitImageUpload";
import { radioClasses, radioLabelClasses, radioWrapperClasses } from "./radio";
import { ocmTextInputClassification, aboveFieldLabel } from "./formKitInput";

const radioClassification = {
	options: `ml-0 mb-0 block list-none`,
	legend: `mb-2`,
	wrapper: radioWrapperClasses,
	inner: `inline-flex`,
	label: radioLabelClasses + `mx-2.5`,
	input: radioClasses,
};

const buttonClassification = {
	wrapper: `mb-1`,
	input: buttonDefaults + buttonPrimary + dimensionsButton,
};

const buttonSecondaryClassification = {
	wrapper: `mb-1`,
	input: buttonDefaults + buttonSecondary + dimensionsButton,
};

// export our definitions using our above
// templates and declare one-offs and
// overrides as needed.
export default {
	// the global key will apply to all inputs
	global: {
		outer: `mb-5 formkit-disabled:opacity-50`,
		help: `text-xs mt-4`,
		messages: `list-none p-0 mt-1 mb-0 ml-0`,
		message: `text-error mb-2 text-xs mt-1 pl-4`,
		fieldset: `mb-0 border-none`,
	},
	ocmTextInput: ocmTextInputClassification,
	file: fileClassification,
	radio: radioClassification,
	button: buttonSecondaryClassification,
	submit: buttonClassification,
	select: {
		outer: `formkit-disabled:opacity-100`,
		input: `
			px-4
			pr-10
			box-border
			leading-5
			text-black
			text-base
			rounded-t-lg
			border-0
			border-b
			w-full
			max-width-100
			h-15
			bg-no-repeat
			bg-icon
			bg-[right_1.142857142857143rem_center]
			bg-gray-20
			cursor-pointer
			placeholder-shown:bg-gray-20
			hover:bg-white-hover
			focus:text-black
			hover:text-black
			em64:pointer-events-none
			em64:invisible
			disabled:text-gray-50
			hover:disabled:text-gray-50
			disabled:bg-arrowDownGray50
			hover:disabled:bg-arrowDownGray50
			disabled:bg-gray-20
			hover:disabled:bg-gray-20
			disabled:border-gray-50
			disabled:opacity-100
			disabled:cursor-not-allowed
			outline-none
			appearance-none
		`,
	},
	textarea: {
		label: aboveFieldLabel,
		input: `
			h-32
			p-4
			focus:shadow-outline 
			w-full
			bg-gray-20
			border-0
			box-border
			rounded-lg
			text-base
		`,
	},
};
