export const SELECTION_PAGE_NAME = "selectionPage";
export const HELP_PAGE_NAME = "helpPage";
export const LOGIN_INFO_PAGE_NAME = "loginInfoPage";
export const ORDER_PAGE_NAME = "orderPage";
export const IMAGE_UPLOAD_PAGE_NAME = "imageUploadPage";
export const DESCRIPTION_PAGE_NAME = "descriptionPage";
export const PERSONAL_INFO_PAGE_NAME = "personalInfoPage";
export const THANK_YOU_PAGE_NAME = "thankYouPage";

export const FORM_ROUTE_NAMES = [
	ORDER_PAGE_NAME,
	IMAGE_UPLOAD_PAGE_NAME,
	DESCRIPTION_PAGE_NAME,
	PERSONAL_INFO_PAGE_NAME,
] as const;

export type FormRouteName = (typeof FORM_ROUTE_NAMES)[number];
