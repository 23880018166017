"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contactPhoneNumber = void 0;
var ocm_object_1 = require("@ocm/services/ocm-object");
var contactPhoneNumber = function () {
    switch (ocm_object_1.country) {
        case "ch":
            return "+41 (0) 43 - 5087740";
        case "at":
            return "+43 (0) 720 - 88 23 90";
        default:
            return "+49 (0) 7123 - 17 89 978";
    }
};
exports.contactPhoneNumber = contactPhoneNumber;
