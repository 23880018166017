import { ref } from "vue";
import { $axios } from "@ocm/axios";
import { trackSearchLoadCaptchaUnforeseenError } from "@/composables/use-trackingErrors";
import { CaptchaResult } from "@/types/captchaResult.type";

export const fetching = ref(false);
export const error = ref();
export const captchaData = ref<CaptchaResult>();

export const getCaptchaData = async (): Promise<void> => {
	try {
		fetching.value = true;
		const result = await $axios.get<CaptchaResult>(`rest/contactcenter/captcha`);
		captchaData.value = result.data;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		error.value = err;
		console.error(err);
		trackSearchLoadCaptchaUnforeseenError(err.response);
	} finally {
		fetching.value = false;
	}
};
