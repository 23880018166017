<template>
	<Transition name="fade" appear mode="out-in">
		<slot />
	</Transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	pointer-events: none;
	transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
