import { $axios } from "@ocm/axios";
import { isRoleFullAccess } from "@/services/authlevel";
import { computed, ref } from "vue";

export const currentAuthLevel = ref();

export const getAuthLevel = async () => {
	try {
		const { data: authLevel } = await $axios.get("/login/authLevel");
		currentAuthLevel.value = authLevel;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		error.message && console.log(error.message);
	}
	return false;
};

export const hasFullAccess = computed(() => isRoleFullAccess(currentAuthLevel.value));
