import { ref } from "vue";
import { $axios } from "@ocm/axios";
import { Collection, Publications } from "@/types/publications.type";

export const fetching = ref(false);
export const error = ref();
export const publicationData = ref<Publications>();

export const getPublications = async (): Promise<void> => {
	try {
		fetching.value = true;
		const result = await $axios.get<Publications>(`rest/contactcenter/publications`);
		publicationData.value = result.data.data;
	} catch (err) {
		error.value = err;
		console.log(err);
	} finally {
		fetching.value = false;
	}
};

export function getCollectionFromId(collectionId: string): Collection | undefined {
	return publicationData.value?.publication?.collections.find(
		(collection: Collection) => collection.id === collectionId
	);
}
