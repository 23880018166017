import { createWebHistory, createRouter, RouteRecordName } from "vue-router";
import { nextTick } from "vue";
import { contextPath, language } from "@ocm/services/ocm-object";
import SelectionPage from "./views/SelectionPage.vue";
import HelpPage from "./views/HelpPage.vue";
import LoginInfoPage from "./views/LoginInfoPage.vue";
import OrderPage from "./views/OrderPage.vue";
import DescriptionPage from "./views/DescriptionPage.vue";
import PersonalDataPage from "./views/PersonalDataPage.vue";
import ImageUploadPage from "./views/ImageUploadPage.vue";
import ThankYouPage from "./views/ThankYouPage.vue";
import { trackVirtualPageView } from "./composables/use-trackingVirtualPageViews";
import { getCollectionFromId, getPublications, publicationData } from "./queries/publications.query";
import { currentCollection, currentFormFlow } from "./composables/use-polly";
import { rerouteIfDeepLink } from "./composables/use-redirect";
import { hasUserPermission } from "./composables/use-restrictions";
import {
	DESCRIPTION_PAGE_NAME,
	FORM_ROUTE_NAMES,
	FormRouteName,
	HELP_PAGE_NAME,
	IMAGE_UPLOAD_PAGE_NAME,
	LOGIN_INFO_PAGE_NAME,
	ORDER_PAGE_NAME,
	PERSONAL_INFO_PAGE_NAME,
	SELECTION_PAGE_NAME,
	THANK_YOU_PAGE_NAME,
} from "./types/routeName.type";
import { startContactForm } from "./composables/use-pageNavigation";

const routes = [
	{
		path: `${contextPath}/:contactBasePath/`,
		name: SELECTION_PAGE_NAME,
		component: SelectionPage,
	},
	{
		path: `${contextPath}/:contactBasePath/:collectionId`,
		name: HELP_PAGE_NAME,
		component: HelpPage,
		props: true,
	},
	{
		path: `${contextPath}/:contactBasePath/:collectionId/loginInfo`,
		name: LOGIN_INFO_PAGE_NAME,
		component: LoginInfoPage,
	},
	{
		path: `${contextPath}/:contactBasePath/:collectionId/order`,
		name: ORDER_PAGE_NAME,
		component: OrderPage,
	},
	{
		path: `${contextPath}/:contactBasePath/:collectionId/imageupload`,
		name: IMAGE_UPLOAD_PAGE_NAME,
		component: ImageUploadPage,
	},
	{
		path: `${contextPath}/:contactBasePath/:collectionId/description`,
		name: DESCRIPTION_PAGE_NAME,
		component: DescriptionPage,
	},
	{
		path: `${contextPath}/:contactBasePath/:collectionId/customer`,
		name: PERSONAL_INFO_PAGE_NAME,
		component: PersonalDataPage,
	},
	{
		path: `${contextPath}/:contactBasePath/confirmation`,
		name: THANK_YOU_PAGE_NAME,
		component: ThankYouPage,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		if (window.innerWidth <= 720) {
			return {
				el: "#contact",
				top: 60, // px above the element, offset the global header
			};
		}
		return { top: 0 };
	},
});

router.beforeEach(async () => {
	if (!publicationData.value) {
		await getPublications();
	}
});

router.afterEach(async () => {
	await rerouteIfDeepLink();
	setCollectionFromRoute();

	nextTick(async () => {
		const routeName = router.currentRoute.value.name;
		const currentCollectionId = currentCollection.value?.id;
		rerouteIfNameNotFound(routeName);
		rerouteIfNoPermission(routeName as RouteRecordName, currentCollectionId);
		rerouteToFirstFormStepIfEndpointDoesNotExistInCurrentFlow(routeName as RouteRecordName, currentCollectionId);
		trackVirtualPageView();
	});
});

export function setCollectionFromRoute(): void {
	const collectionIdParams = router.currentRoute.value.params.collectionId;
	const isCollectionObject = typeof collectionIdParams === "object";
	const collectionIdParam = isCollectionObject ? collectionIdParams[0] : collectionIdParams;
	if (collectionIdParam) {
		currentCollection.value = getCollectionFromId(collectionIdParam);
	}
}

export async function rerouteIfNoPermission(routeName: RouteRecordName, collectionId?: string): Promise<void> {
	if (!(await hasUserPermission()) && routeName && FORM_ROUTE_NAMES.includes(routeName as FormRouteName)) {
		router.replace({ name: LOGIN_INFO_PAGE_NAME, params: { collectionId } });
	}
}

function rerouteToFirstFormStepIfEndpointDoesNotExistInCurrentFlow(
	routeName: RouteRecordName,
	collectionId?: string
): void {
	if (
		routeName &&
		FORM_ROUTE_NAMES.includes(routeName as FormRouteName) &&
		!currentFormFlow.value.includes(routeName as FormRouteName)
	) {
		startContactForm(collectionId, true);
	}
}

function rerouteIfNameNotFound(routeName?: RouteRecordName | null): void {
	if (!routeName) {
		const contactBasePath = language === "de" ? "kontakt" : "contact";
		router.replace({ name: SELECTION_PAGE_NAME, params: { contactBasePath } });
	}
}

export default router;
