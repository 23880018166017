import { ref } from "vue";
import { FormKitMessageWrapper } from "@/types/formkitMessage.type";
import { FormKitMessage, FormKitNode } from "@formkit/core";
import { pushGAevent } from "@/services/pushGAevent";

// Please keep the Confluence documentation up-to-date: https://redbox.outletcity.com/confluence/display/TUB/FR+-+Custom-Tracking

export function useErrorTracking(): {
	trackingPlugin: (node: FormKitNode) => void;
} {
	const errorMessage = ref<string | undefined>();

	const changeErrorMsg = (formKitMessage: FormKitMessage) => {
		const messageType = formKitMessage.key;
		const message = formKitMessage.value;

		if ((messageType === "rule_required" || messageType === "rule_matches") && typeof message === "string") {
			errorMessage.value = message;
		} else if (messageType === "validating" && message === true) {
			errorMessage.value = undefined;
		} else if (messageType === "submitted") {
			trackFormErrors();
		}
	};

	const trackFormErrors = () => {
		if (!errorMessage.value) return;
		pushGAevent("form_errors", errorMessage.value);
	};

	const trackingPlugin = (node: FormKitNode) => {
		node.on("message-added", (message: FormKitMessageWrapper) => {
			changeErrorMsg(message.payload);
		});
		node.on("message-updated", (message: FormKitMessageWrapper) => {
			changeErrorMsg(message.payload);
		});
	};

	return {
		trackingPlugin,
	};
}

export const trackImageSizeToLarge = (t: { (key: string | number): string }) => {
	pushGAevent("form_errors", t("imageUploadPage.fileSizeLimitInfo"));
};

export const trackImageWrongType = (t: { (key: string | number): string }) => {
	pushGAevent("form_errors", t("imageUploadPage.errorFileTyp"));
};

export const trackSearchTermNotLongEnough = (searchTerm: string) => {
	pushGAevent("faq_search_error_response", `search_term_not_long_enough: ${searchTerm}`);
};

export const trackSearchSubmitUnforeseenError = (errorResponse: unknown) => {
	trackBackendError("search_submit", errorResponse);
};

export const trackSearchLoadCaptchaUnforeseenError = (errorResponse: unknown) => {
	trackBackendError("load_captcha", errorResponse);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackBackendError = (error_action: string, errorResponse: any) => {
	pushGAevent("backend_error", `${error_action}: ${errorResponse?.status}_${errorResponse?.statusText}`);
};

export const trackImageCompressionError = (size: number, fileType?: string | null) => {
	pushGAevent("image_compression", `size_${size}_file_${fileType}`);
};
