import { ref } from "vue";
import { $axios } from "@ocm/axios";

export const data = ref();
export const getAnnouncements = async () => {
	try {
		const result = await $axios.get(`rest/contactcenter/announcements`);
		data.value = result.data.data;
	} catch (err) {
		console.error(err);
	}
};
