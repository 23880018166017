import { createApp } from "vue";
import { generateClasses } from "@formkit/themes";
import { de as deForm, en as enForm } from "@formkit/i18n";
import { plugin, defaultConfig } from "@formkit/vue";
import formKitTheme from "../../../styles/formKitTheme.js";
import { ocmTextInput } from "../../../formKitElements/formKitInput";
import { ocmChipRadio, ocmChipCheckbox } from "../../../formKitElements/formKitChip";

import router from "./router";
import { language } from "@ocm/services/ocm-object";
import ContactLayout from "./layouts/ContactLayout.vue";
import { createI18n } from "vue-i18n";

import de from "@/i18n/translations/de.json";
import en from "@/i18n/translations/en.json";

import { setWebpackPublicPath } from "../../shared/js/webpack-utils";
setWebpackPublicPath();

const getLanguageForm = (): string => {
	if (language === "de" || language === "at" || language === "ch") {
		return "deForm";
	}
	return "enForm";
};

// Creation of the i18n instance needs to be in this file, otherwise flaky build issues will occur. See TUB-19197
const i18n = createI18n({
	locale: language,
	messages: { de, en },
	fallbackLocale: "en",
	legacy: false,
});

const app = createApp(ContactLayout);
app.use(i18n);
app.use(router);
app.use(
	plugin,
	defaultConfig({
		locales: { deForm, enForm },
		locale: getLanguageForm(),
		inputs: {
			// The property will be the “type” in <FormKit type="ocmTextInput">
			// type of input is declared as eg. input-type="text" / input-type="number"
			// eslint-disable-next-line
			// @ts-ignore
			ocmTextInput,
			// eslint-disable-next-line
			// @ts-ignore
			ocmChipRadio,
			// eslint-disable-next-line
			// @ts-ignore
			ocmChipCheckbox,
		},
		config: {
			// eslint-disable-next-line
			// @ts-ignore
			classes: generateClasses(formKitTheme),
		},
	})
);
app.mount("#mf-contact");
