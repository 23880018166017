import { Ref, ref, computed } from "vue";
import { FormRouteName } from "@/types/routeName.type";
import { Collection } from "@/types/publications.type";

export const currentCollection: Ref<Collection | undefined> = ref();

/*
	POLLY MAPPING
		There are several more ids than listed here, every section (e.g ANMELDUNG_BENUTZERKONTO, NEWSLETTER, KARRIERE...) 
		which is not defined will be handled like the default case
*/

// TestShop, Shop, TestShop AT, Shop AT, Testshop CH, Shop CH
const VERSAND_LIEFERUNG = [
	"aJu7zeEpzWkvAunZx",
	"KSDEdjJKktKxvRpen",
	"AGDKxKdBcbjfxmNqG",
	"tieDsj6rfRzfErtjw",
	"nWQLnhgcijBcRo83v",
	"Z84WogMuDiPjS8BeR",
];
const RETOURE_RUECKERSTATTUNG = [
	"2ZS4CgqmQZc4bzzPA",
	"3X9RGchGo3FSMsBhf",
	"kgQia4cnSJ39FcBtu",
	"SfyTi8p8MKtjn7mDd",
	"rGrAkr5vxNcxy8SHb",
	"4Jpt9EhaaGdaAHi9o",
];
const ZAHLUNG = [
	"n7GpHYTw3vYH8JspC",
	"tN5MmQy4hE6FtSSM2",
	"jHHjbDneytebgTH3L",
	"rBtXsbfbjMwqgBf9f",
	"783L6rPcMb42Krzt7",
	"a2XEFyuHHhRoDv6hp",
];
const GUTSCHEINE = [
	"GgAyPFsvhxT3dJcyX",
	"oShbYMkLAyCaKX4ti",
	"JMXpJ2cgHu2FXaLfP",
	"TsCjqhwSFw3v2n9EK",
	"sRznxLv2G33sEzAig",
	"cLNWukcZGhpZgrkb7",
];
const BESTELLUNG = [
	"3hjKSkfHK7XRcNahf",
	"HLnr9Wef99QxnxsxT",
	"MuzgaXeXM6XmJHCJM",
	"iZn9emvAYHkjLhauP",
	"qow6YW2DeyqnQqGxX",
	"yhEY5kiiXDjbioQni",
];
const REKLAMATION_EINES_ARTIKELS_SHOP = [
	"uTXnJaSBsHp6tMFCk",
	"W7yPstetW7t3LCgJx",
	"b82X7xLN9ptdPKHe3",
	"QxuGdREZADcHuPjfu",
	"TfT9pjRgom9MfxR4Y",
	"tABGCJvpNzuF7vHBT",
];

// Test
export const SONSTIGE = "z8SvrCnTPe6BFRL9X"; /* special don't show faq */

const findCurrentCollectionId = (allIds: string[]): string | undefined =>
	allIds.find((id) => currentCollection.value?.id === id);

export const currentFormFlow = computed((): FormRouteName[] => {
	const currentCollectionId = currentCollection.value?.id || "";
	switch (currentCollectionId) {
		case findCurrentCollectionId([
			...BESTELLUNG,
			...VERSAND_LIEFERUNG,
			...RETOURE_RUECKERSTATTUNG,
			...ZAHLUNG,
			...GUTSCHEINE,
		]):
			return ["orderPage", "descriptionPage", "personalInfoPage"];

		case findCurrentCollectionId([...REKLAMATION_EINES_ARTIKELS_SHOP]):
			return ["orderPage", "imageUploadPage", "descriptionPage", "personalInfoPage"];

		default:
			return ["descriptionPage", "personalInfoPage"];
	}
});
