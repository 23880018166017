"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'TransitionExpand',
    setup: function (__props) {
        var enter = function (element) {
            var width = getComputedStyle(element).width;
            element.style.width = width;
            element.style.position = "absolute";
            element.style.visibility = "hidden";
            element.style.height = "auto";
            var height = getComputedStyle(element).height;
            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;
            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;
            // Trigger the animation.
            // We use `requestAnimationFrame` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            requestAnimationFrame(function () {
                element.style.height = height;
            });
        };
        var afterEnter = function (element) { return (element.style.height = "auto"); };
        var leave = function (element) {
            var height = getComputedStyle(element).height;
            element.style.height = height;
            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;
            requestAnimationFrame(function () {
                element.style.height = 0;
            });
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(vue_2.Transition, {
                name: "expand",
                onEnter: enter,
                onAfterEnter: afterEnter,
                onLeave: leave
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.renderSlot)(_ctx.$slots, "default")
                ]; }),
                _: 3
            }));
        };
    }
});
