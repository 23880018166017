import { ref } from "vue";
import { $axios } from "@ocm/axios";
import { Result } from "@/types/searchResult.type";
import { trackSearchTerm } from "@/composables/use-trackingInteractions";
import { trackSearchSubmitUnforeseenError, trackSearchTermNotLongEnough } from "@/composables/use-trackingErrors";
import router from "@/router";

export const fetching = ref(false);
export const error = ref();
export const resultResponse = ref<Result[] | null | undefined>();
export const lastSearchTerm = ref();

export const getSearchRequest = async (input: string) => {
	try {
		router.push({ query: { search: input } });
		fetching.value = true;
		resultResponse.value = undefined;
		const result = await $axios.get<Result[]>(`rest/contactcenter/search`, {
			params: { query: input },
		});
		lastSearchTerm.value = input;
		resultResponse.value = result.data.data.search.results;
		error.value = "";
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		if (err.response.status === 400) {
			error.value = "missing_characters_error";
			trackSearchTermNotLongEnough(input);
		} else {
			error.value = "generic_error";
			trackSearchSubmitUnforeseenError(err.response);
		}
	} finally {
		fetching.value = false;
		const numberOfResultsOrError = resultResponse.value?.length ?? error.value;
		trackSearchTerm(input, numberOfResultsOrError);
	}
};
