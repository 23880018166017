import { genericInput } from "./formKitInput";

export const fileClassification = {
  label: `mb-2 block font-semibold pointer-events-none`,
  inner: `
    relative 
    text-gray-80 
    hover:text-black
    formkit-invalid:text-error
  `,
  fileList: `
    ${genericInput}
    font-normal
    absolute
    flex
    items-center
    top-0
    ml-0
    pr-10.5
    h-15
    list-none
    border-solid
    whitespace-nowrap
    overflow-hidden
  `,
  fileItem: `
    flex
    justify-between
    w-full
    items-center
  `,
  fileRemove: `
    opacity-0
    w-5
    h-5
    absolute
    right-4
  `,
  input: `
    w-full
    leading-[60px]
    file:h-15
    file:border-0
    file:border-b
    file:border-solid

    file:cursor-pointer
    file:px-10.5
    
    file:text-left
    file:text-base
    file:text-gray-80
    file:font-['Draft_B']

    file:rounded-t-lg
    file:p-4
    file:border-gray-80
    file:w-full
    file:max-width-100
    file:placeholder-gray-80
    file:border-0
    file:bg-gray-20
    
    file:hover:bg-gray-40
    file:hover:text-black

    file:formkit-invalid:placeholder-error
    file:formkit-invalid:border-error
    file:formkit-invalid:text-error
    file:appearance-none
		file:rounded-b-none
  `,
}