import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";
import { contextPath } from "@ocm/services/ocm-object";
import { isRoleAnonymous, isRoleCoop, isRoleEasyAuth, isRoleRememberMe } from "@/services/authlevel";
import { getStepLocalStorage, resetStepToLocalStorage, saveStepToLocalStorage } from "@/services/localStorage";
import { currentAuthLevel } from "@/services/checkAuth";
import { currentCollection } from "./use-polly";
import { startContactForm } from "./use-pageNavigation";
import { HELP_PAGE_NAME } from "@/types/routeName.type";
import { getCollectionFromId } from "@/queries/publications.query";
import router from "@/router";

// Reauth/Log-in

export const redirectLocalStorage = async (): Promise<void> => {
	const localStoreStep = getStepLocalStorage();
	if (localStoreStep) {
		currentCollection.value = getCollectionFromId(localStoreStep);
		startContactForm(currentCollection.value?.id, true);
		resetStepToLocalStorage();
	}
};

const goToReauthPage = (): void => {
	window.location.href = `${contextPath}/my-account/re-auth/`;
};
const openLoginlayer = (): void => {
	OcmMessenger.publish(messengerPublicationTypes.REQUEST_LOGIN);
};

export const triggerReauthOrLogin = (): void => {
	saveStepToLocalStorage();
	const authLevel = currentAuthLevel.value;

	if (isRoleRememberMe(authLevel) || isRoleEasyAuth(authLevel)) {
		goToReauthPage();
	}
	if (isRoleAnonymous(authLevel) || isRoleCoop(authLevel)) {
		OcmMessenger.subscribe(messengerPublicationTypes.ABORTED_LOGIN, resetStepToLocalStorage);
		openLoginlayer();
	}
};

// Deep links

export const rerouteIfDeepLink = async (): Promise<void> => {
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	const collectionIdFromParam = params.get("collection");
	const faqIdFromParam = params.get("faq");
	if (collectionIdFromParam) {
		redirectToParam(collectionIdFromParam, faqIdFromParam);
	}
};

const redirectToParam = (collectionIdFromParam: string, faqIdFromParam: string | null): void => {
	const collection = getCollectionFromId(collectionIdFromParam);
	if (collection) {
		currentCollection.value = collection;
		router.replace({
			name: HELP_PAGE_NAME,
			params: {
				collectionId: collectionIdFromParam,
			},
			query: { faq: faqIdFromParam },
		});
	}
};
