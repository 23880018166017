"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-107cbb88"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = {
    key: 0,
    class: "pt-4 font-medium"
};
var _hoisted_2 = { class: "mb-4 font-semibold" };
var vue_3 = require("vue");
var SvgIcon_vue_1 = require("@SharedVueComponents/SvgIcon/SvgIcon.vue");
var feedback_query_1 = require("@/queries/feedback.query");
var use_trackingInteractions_1 = require("@/composables/use-trackingInteractions");
var use_trackingErrors_1 = require("@/composables/use-trackingErrors");
var vue_i18n_1 = require("vue-i18n");
var BaseDropdown_vue_1 = require("@/components/BaseDropdown.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BaseFeedback',
    props: {
        articleId: {},
        articleName: {}
    },
    setup: function (__props) {
        var props = __props;
        var showFeedbackSuccess = (0, vue_3.computed)(function () { return !!feedback_query_1.showFeedbackSuccessIds.value.find(function (id) { return id === props.articleId; }); });
        var feedbackReason = (0, vue_3.computed)(function () { return optionTranslationKeys.indexOf(selection.value); });
        var content = (0, vue_3.ref)("");
        var selection = (0, vue_3.ref)("");
        var showFeedbackOptions = (0, vue_3.ref)(true);
        var negativeFeedback = function () {
            showFeedbackOptions.value = false;
            (0, use_trackingInteractions_1.trackNotHelpfulBtn)(props.articleName);
        };
        var optionTranslationKeys = ["unclear", "containsErrors", "wrongQuestion", "stillNeedHelp"];
        var t = (0, vue_i18n_1.useI18n)().t;
        var feedbackOptions = optionTranslationKeys.reduce(function (acc, key) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[key] = t("helpPage.reasons.".concat(key)), _a)));
        }, {});
        /* manually trigger form submit / validation because button looks different */
        var feedbackForm = (0, vue_3.ref)();
        var submitForm = function () {
            var _a;
            var node = (_a = feedbackForm === null || feedbackForm === void 0 ? void 0 : feedbackForm.value) === null || _a === void 0 ? void 0 : _a.node;
            node.submit();
        };
        return function (_ctx, _cache) {
            var _component_FormKit = (0, vue_2.resolveComponent)("FormKit");
            return (showFeedbackSuccess.value)
                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, (0, vue_2.toDisplayString)(_ctx.$t("helpPage.thanksForFeedback")), 1))
                : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 1 }, [
                    (showFeedbackOptions.value)
                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 0 }, [
                            (0, vue_2.createElementVNode)("div", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.$t("helpPage.wasQuestionAnswered")), 1),
                            (0, vue_2.createElementVNode)("button", {
                                type: "button",
                                class: "mr-6 inline-flex items-center",
                                onClick: _cache[0] || (_cache[0] = function ($event) { return ((0, vue_2.unref)(feedback_query_1.sendFeedback)(_ctx.articleId, _ctx.articleName, 1)); })
                            }, [
                                (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                    "icon-name": "hook",
                                    class: "mr-2 h-3.5 w-3.5"
                                }),
                                (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(_ctx.$t("helpPage.yes")), 1)
                            ]),
                            (0, vue_2.createElementVNode)("button", {
                                type: "button",
                                class: "inline-flex items-center",
                                onClick: negativeFeedback
                            }, [
                                (0, vue_2.createVNode)(SvgIcon_vue_1.default, {
                                    "icon-name": "close",
                                    class: "mr-2 h-3.5 w-3.5"
                                }),
                                (0, vue_2.createTextVNode)(" " + (0, vue_2.toDisplayString)(_ctx.$t("helpPage.no")), 1)
                            ])
                        ], 64))
                        : ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_FormKit, {
                            key: 1,
                            ref_key: "feedbackForm",
                            ref: feedbackForm,
                            type: "form",
                            config: { validationVisibility: 'submit' },
                            actions: false,
                            errors: [_ctx.$t((0, vue_2.unref)(feedback_query_1.feedbackErrorText))],
                            onSubmit: _cache[3] || (_cache[3] = function ($event) { return ((0, vue_2.unref)(feedback_query_1.sendFeedback)(_ctx.articleId, _ctx.articleName, 0, content.value, feedbackReason.value)); })
                        }, {
                            default: (0, vue_2.withCtx)(function () { return [
                                (0, vue_2.createVNode)(BaseDropdown_vue_1.default, {
                                    modelValue: selection.value,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) { return ((selection).value = $event); }),
                                    label: _ctx.$t('helpPage.reasonLabel'),
                                    name: _ctx.$t('helpPage.reasonInputName'),
                                    options: (0, vue_2.unref)(feedbackOptions),
                                    placeholder: _ctx.$t('helpPage.reasonPlaceholder'),
                                    plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin],
                                    validation: "required"
                                }, null, 8, ["modelValue", "label", "name", "options", "placeholder", "plugins"]),
                                (0, vue_2.createVNode)(_component_FormKit, {
                                    modelValue: content.value,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) { return ((content).value = $event); }),
                                    label: _ctx.$t('helpPage.inputLabel'),
                                    plugins: [(0, vue_2.unref)(use_trackingErrors_1.useErrorTracking)().trackingPlugin],
                                    name: "textarea",
                                    type: "textarea",
                                    "outer-class": "$reset mb-8"
                                }, null, 8, ["modelValue", "label", "plugins"]),
                                (0, vue_2.createVNode)(_component_FormKit, {
                                    type: "button",
                                    label: _ctx.$t('helpPage.sendBtnLabel'),
                                    onClick: submitForm
                                }, null, 8, ["label"])
                            ]; }),
                            _: 1
                        }, 8, ["errors"]))
                ], 64));
        };
    }
});
