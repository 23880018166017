import { Ref, ref } from "vue";
import { FileUpload } from "@/types/image.type";
import { currentCollection } from "@/composables/use-polly";
import { getEmailImages } from "@/composables/use-imageUpload";
import { EmailBody, EmailImage } from "@/types/email.type";
import { isClassicSite, language } from "@ocm/services/ocm-object";
import { authRequired } from "./use-restrictions";
import { wasAutofillExecuted } from "./use-autofill";

export const orderNumber: Ref<number | null> = ref(null);
export const description: Ref<string> = ref("");

export const phone: Ref<string> = ref("");
export const email: Ref<string> = ref("");
export const emailDisabled = ref(false);
export const lastName: Ref<string> = ref("");
export const firstName: Ref<string> = ref("");
export const salutation: Ref<string> = ref("");

export const isShoe: Ref<boolean> = ref(false);

export const articleImage: Ref<Array<FileUpload>> = ref([]);
export const defectImage: Ref<Array<FileUpload>> = ref([]);
export const soleImage: Ref<Array<FileUpload>> = ref([]);
export const receiptImage: Ref<Array<FileUpload>> = ref([]);
export const optionalImage1: Ref<Array<FileUpload>> = ref([]);
export const optionalImage2: Ref<Array<FileUpload>> = ref([]);

export function resetInputData(): void {
	orderNumber.value = null;
	description.value = "";

	phone.value = "";
	email.value = "";
	emailDisabled.value = false;
	lastName.value = "";
	firstName.value = "";
	salutation.value = "";

	isShoe.value = false;

	articleImage.value = [];
	defectImage.value = [];
	soleImage.value = [];
	receiptImage.value = [];
	optionalImage1.value = [];
	optionalImage2.value = [];
}

export function isContactFormDirty(): boolean {
	const autofillFields = [email.value, lastName.value, firstName.value, salutation.value];
	const nonAutofillFields = [
		orderNumber.value,
		description.value,
		phone.value,
		articleImage.value,
		defectImage.value,
		soleImage.value,
		receiptImage.value,
		optionalImage1.value,
		optionalImage2.value,
	];
	const fields = wasAutofillExecuted ? nonAutofillFields : nonAutofillFields.concat(autofillFields);

	return fields.some((field) => {
		if (Array.isArray(field)) return field.length !== 0;
		return !!field;
	});
}

export const returnEmailObject = async (): Promise<EmailBody> => {
	const data: EmailBody = {
		language,
		referTo: isClassicSite ? "Classic" : "Shop",
		topic: currentCollection.value?.name || "",
		orderNumber: orderNumber.value,
		phoneNumber: phone.value,
		email: email.value,
		surName: lastName.value,
		firstName: firstName.value,
		salutation: salutation.value,
		message: description.value,
		customerNumber: getCustomerNumber(),
	};

	if (authRequired.value) {
		const imageRefs = [articleImage, defectImage, receiptImage, optionalImage1, optionalImage2];

		if (isShoe.value) imageRefs.push(soleImage);

		const images: Array<EmailImage> = await getEmailImages(imageRefs);

		data.isShoe = isShoe.value;
		data.images = images;
	}

	return data;
};

export const getCustomerNumber = () => window.recommendationData?.Recommendation?.customerData?.customerId;
