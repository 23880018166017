export const genericInput = `
  box-border
  text-base
  rounded-t-lg
  bg-gray-20
  p-4
  border-0
  border-b
  border-gray-80
  w-full
  max-width-100
  placeholder-gray-80
  formkit-invalid:placeholder-error
  formkit-invalid:border-error
  disabled:bg-gray-20
  disabled:text-gray-50
  disabled:border-gray-50
  focus:bg-white-hover
  hover:bg-white-hover
  leading-5
  autofill:shadow-input
  autofill:hover:shadow-inputHover
`;

// --- inFieldLabel ---

export const aboveFieldLabel = `
	block
	mb-4
	font-semibold
	text-sm
`;

export const inFieldLabel = `
  text-gray-80
  top-2
  pointer-events-none
  absolute
  left-4
  ease
  text-xs
  duration-200
  peer-formkit-invalid:text-error
  formkit-invalid:!text-error
  hover:!text-black
`;

export const activeInFieldLabel = `
  ${inFieldLabel}
  top-[1.28571429rem]
  !text-base
  hover:!text-gray-80
`;

// --- ocmTextInput ---

const ocmTextInput = genericInput + `
  peer
  placeholder-transparent
  pb-3.5
  pt-6.5
  px-4
`;

const ocmTextInputLabel =
  inFieldLabel + `
  peer-placeholder-shown:top-[1.32142857rem]
  peer-placeholder-shown:!text-base
  peer-focus:top-2
  peer-focus:!text-xs
  peer-disabled:text-gray-50
  leading-4.5
`;

export const ocmTextInputClassification = {
	wrapper: `relative`,
	input: ocmTextInput,
	label: ocmTextInputLabel,
};